<template>
  <v-row class="ma-4">
    <v-col>
      <v-card>
        <v-card
          :disabled="bloquear || (!multiple ? (files.length >= 1 ? true : false) : false)"
          flat
        >
          <v-card-text class="pt-6 pb-0">
            <v-row
              class="d-flex justify-center mx-4"
              style="border-style: dashed; border-width: 2px; border-radius: 15px; cursor: pointer;"
              :style="$vuetify.theme.dark ? 'background-color: #0f0f0f;' : 'background-color: #f0f0f0;'"
              no-gutters
              @dragover.prevent="hover = true"
              @dragleave.prevent="hover = false"
              @drop.prevent="drop"
              @click="$refs.input.click()"
            >
              <input
                ref="input"
                type="file"
                class="d-none"
                :multiple="multiple"
                :accept="tipos.join(',')"
                @change="buscar($event)"
              >
              <v-col cols="12" class="d-flex justify-center mt-6">
                <v-icon
                  :large="hover ? false : true"
                  :x-large="hover ? true : false"
                >
                  fas fa-folder{{ hover ? '-open' : '' }}
                </v-icon>
              </v-col>
              <v-col cols="12" class="d-flex justify-center mt-2 mb-4">
                <h1 class="body-1 font-weight-bold mx-4 text-center">
                  Arrastre {{ multiple ? 'los' : 'el' }} archivo{{ multiple ? 's' : '' }} o haga click aquí
                </h1>
              </v-col>
            </v-row>
            <div
              class="d-flex body-2 pt-2 mx-4"
              style="cursor: default"
            >
              <div>
                Tipo{{ tipos.length > 1 ? 's' : '' }} de archivo{{ multiple ? 's' : '' }} válido{{ multiple ? 's' : '' }}: {{ tipos.join(', ') }}
              </div>
              <v-spacer></v-spacer>
              <div
                class="text-right"
                title="Por archivo"
              >
                Peso máximo: {{ max_size }}MB
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Nombre
                  </th>
                  <th class="text-right">
                    Acciones
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in files"
                  :key="index"
                >
                  <td>
                    <v-icon
                      :color="get_tipo_archivo(item.type, 'color')"
                      small
                      left
                    >
                      {{ get_tipo_archivo(item.type, 'icono') }}
                    </v-icon>
                    {{ item.name }}
                  </td>
                  <td class="text-right">
                    <v-btn
                      v-show="get_tipo_archivo(item.type, 'tipo') === 'PDF' || get_tipo_archivo(item.type, 'tipo') === 'IMG'"
                      color="info"
                      title="Previsualizar"
                      small
                      icon
                      @click="previsualizar(item, get_tipo_archivo(item.type, 'tipo'))"
                    >
                      <v-icon small>fas fa-eye</v-icon>
                    </v-btn>
                    <v-btn
                      color="error"
                      title="Quitar"
                      small
                      icon
                      :disabled="bloquear"
                      @click="quitar(item)"
                    >
                      <v-icon small>fas fa-trash</v-icon>
                    </v-btn>
                  </td>
                  <th class="text-center">
                    <v-icon
                      :title="estados[index] ? estados[index].title : 'Pendiente'"
                      :color="estados[index] ? estados[index].color : ''"
                      small
                    >
                      {{ estados[index] ? estados[index].icono : 'far fa-clock' }}
                    </v-icon>
                  </th>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
      <v-row class="d-flex justify-end py-4 px-3">
        <v-btn
          color="primary"
          class="mt-2"
          :disabled="bloquear  || (!multiple ? (files.length >= 1 ? true : false) : false)"
          @click="$refs.input.click()"
        >
          <v-icon left>fas fa-folder-open</v-icon>
          Seleccionar archivo{{ multiple ? 's' : '' }}
        </v-btn>
        <v-expand-x-transition>
          <v-btn
            v-if="files.length > 0"
            color="success"
            class="mt-2 ml-3"
            :disabled="bloquear"
            @click="subir"
          >
            <v-icon left>fas fa-file-upload</v-icon>
            Subir archivo{{ files.length > 1 ? 's' : '' }}
          </v-btn>
        </v-expand-x-transition>
      </v-row>
    </v-col>

    <PDFViewer
      v-model="dialog_pdf"
      :pdf="pdf"
      :nombre="nombre_ver"
      :titulo="nombre_ver"
    />

    <ImgViewer
      v-model="dialog_img"
      :img="img"
      :nombre="nombre_ver"
    />

  </v-row>
</template>

<script>
/**
 *  Componente genérico para subir archivos.
 *  (todos los props son obligatorios)
 */

import { tipos_archivos, getBase64 } from '../../util/utils'
import PDFViewer from '../../util/plantillas/PDFViewer'
import ImgViewer from './ImgViewer'

export default {
  data() {
    return {
      hover: false,
      bloquear: false,
      dialog_pdf: false,
      dialog_img: false,
      nombre_ver: '',
      pdf: '',
      img: '',
      files: [],
      estados: [],
    }
  },
  props: {
    multiple: Boolean,  // permite subir multiples archivos
    tipos: Array,       // un array de string con las extensiones permitidas, ej: ['.png', '.webp']
    max_size: Number,   // tamaño maximo del archivo en MB
    ruta: String,       // path del servidor 40 donde se van a subir los archivos
    nombre: String,     // nombre por defecto con el que se guardan los archivos. Si no se especifica ningun nombre, el archivo se subira con su nombre original
    alerta: Boolean,    // muestra un modal de exito/revisar errores cuando finaliza la carga
    accion: Boolean,    // permite realizar una accion desde el padre si el archivo se subio correctamente
    raiz: Number,       // indica desde dónde vamos a estar tomando la raiz del 10.40, 0 tomamos /var/www/html/, 1 tomamos /var/www/html/jarvis2.0/
    tabla: Number,      // indica la tabla en la que se va a guardar el log de la subida UNICAMENTE si es exitoso, es decir, no refleja un log de resultados de subida, sino un log con ubicaciones de archivos (ej: la tabla LOG_FACTURAS_COMPRAS)
    id: Number,
    parametros: {
      type: Object,
      default: null
    }

    // NOTAS:
    /** si el prop multiple esta habilitado, guarda todos los archivos con el mismo nombre
     *  separados por un _ y la marca de tiempo
     *  si el prop multiple no esta habilitado, guarda el archivo con el nombre (en este caso
     *  reemplazaria el archivo en el servidor si ya existe uno con el mismo nombre)
     * 
     *  si se habilita el prop 'accion', cuando se llama al coponente se debe espeficiar el
     *  método que realizara mediante: @action="metodo"
     *  al emitir el método al componente padre se envía la respuesta del servidor que
     *  contiene el archivo en base64 y la ruta absoluta al archivo
     */
  },
  components: {
    PDFViewer,
    ImgViewer,
  },
  methods: {
    async subir() {
      this.bloquear = true
      this.$store.state.loading = true
      let errores = 0

      for (let index = 0; index < this.files.length; index++) {
        let file = this.files[index]

        let base46 = ''
        await getBase64(file).then(data => {
          base46 = data.split(',')[1]
        })

        let nombre = ''
        if (this.nombre) {
          if (this.multiple) {
            // crea el nombre seguido de _timestamp
            nombre = this.nombre + '_' + Date.now() + this.get_tipo_archivo(file.type, 'extension')
          } else {
            // crea el nombre del archivo a partir del prop 'nombre' y la extension del archivo
            nombre = this.nombre + this.get_tipo_archivo(file.type, 'extension')
          }
        } else {
          nombre = file.name
        }

        await this.$store.dispatch('upload_file', {
          file: base46,
          name: nombre,
          path: this.ruta,
          raiz: this.raiz,
          tabla: this.tabla,
          id: this.id,
          parametros: this.parametros
        })
          .then(res => {
            if (this.accion) {
              this.$emit('action', res)
            }
            this.estados.push({
              icono: 'fas fa-check',
              color: 'success',
              title: 'Correcto'
            })
          })
          .catch(error => {
            errores += 1
            this.estados.push({
              icono: 'fas fa-exclamation-triangle',
              color: 'warning',
              title: error.message,
            })
          })

      }

      // mensaje personalizado
      if (this.alerta) {
        const len = this.files.length
        let icono = 'success'
        let mensaje = `Archivo${len > 1 ? 's' : ''} subido${len > 1 ? 's' : ''} correctamente
                      <p style="font-size: 22px; margin-top: 12px">Se ${len > 1 ? 'guardaron' : 'guardo'} ${len} archivo${len > 1 ? 's' : ''}</p>`
        if (errores > 0) {
          icono = 'warning'
          mensaje = `Subida finalizada, revise el estado de${len > 1 ? ' los' : 'l'} archivo${len > 1 ? 's' : ''}
                    <p style="font-size: 22px; margin-top: 12px; margin-bottom: 0px">Correctos: ${len - errores} <br/> Con error: ${errores}</p>`
        }
        this.$swal.fire({
          icon: icono,
          title: mensaje
        }) 
      }

      this.$store.state.loading = false
    },
    drop (event) {
      this.hover = false
      const files = event.dataTransfer.files
      if (!this.multiple && files.length > 1) {
        this.$refs.input.value = ''
        this.$store.dispatch('show_snackbar', {
          text: 'Solo puede subir un archivo',
          color: 'error',
        })
        return
      }
      this.validar(files)
    },
    buscar (event) {
      const files = event.target.files
      if (files) this.validar(files)
    },
    validar (files) {
      let errores = []

      for (let index = 0; index < files.length; index++) {

        const file = files[index]
        const tipo = file.type

        // busca en el array de tipos_archivos el tipo de archivo del archivo que subio
        const tipo_archivo = tipos_archivos.find(a => a.mime === tipo)

        if (tipo_archivo) {
          // si encuentra el tipo de archivo pasa a validar que pertenezca a por lo menos un tipo de extension existente en el prop de tipos
          if (this.tipos.find(a => a === tipo_archivo.extension)) {
            // la extension del tipo del archivo existe en los tipos validos
            
            // valida el pesp del archivo (en MB)
            if (file.size <= this.max_size * 1024 * 1024) {
              
              // verifica que no exista un archivo con el mismo nombre en la lista
              if (!this.files.find(a => a.name.toLowerCase() === file.name.toLowerCase())) {
                // guarda el archivo en el array de files
                this.files.push(file)

              } else {
                // archivo duplicado
                errores.push(`<div>Ya hay un archivo con el mismo nombre: ${file.name}</div>`)
              }

            } else {
              // excedio el peso
              errores.push(`<div>El archivo ${file.name} no debe pesar más de ${this.max_size}MB</div>`)
            }

          } else {
            // subio un archivo con una extension no valida
            errores.push(`<div>${file.name}: tipo de archivo no válido</div>`)
          }

        } else {
          // si no lo encuentra es porque subio un tipo de archivo que no esta registrado en el array tipos_archivos
          errores.push(`<div>${file.name}: tipo de archivo no válido para el sistema</div>`)
        }
        
      }

      // limpia el input de archivos
      this.$refs.input.value = ''

      if (errores.length > 0) {
        this.$store.dispatch('show_snackbar', {
          text: errores.join(''),
          color: 'error',
        })
      }
    },
    async previsualizar (file, tipo) {

      this.nombre_ver = file.name

      await getBase64(file).then(data => {
        if (tipo === 'PDF') {
          this.pdf = data
          this.dialog_pdf = true

        } else if (tipo === 'IMG') {
          this.img = data
          this.dialog_img = true

        }
      })

    },
    quitar (file) {
      const index = this.files.indexOf(file)
      this.files.splice(index, 1)
      this.$refs.input.value = ''
    },
    // obtiene un atributo especifico del array tipos_archivos en base al mime del archivo indicado
    get_tipo_archivo (tipo, atributo) {
      if (!tipo) return ''
      return tipos_archivos.find(a => a.mime === tipo)[atributo]
    },
  }
}
</script>