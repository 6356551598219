import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: JSON.parse(localStorage.getItem('dark')),
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#1E88E5',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFB300',
        wpp: '#25D366',
        rowsel2: '#81D4FA',
        rowsel: '#B3E5FC',
        rowsuc: '#A5D6A7',
        rowerr: '#FFCDD2',
        rowwar: '#FFF176',
        swalBkg: '#fff',
        swalText: '#545454',
        cardSuccess: '#A5D6A7',
        cardWarning: '#FFCC80',
        disabled: '#E0E0E0',
        tableVta: '#E0F7FA'
      },
      dark: {
        primary: '#0277BD',
        secondary: '#212121',
        accent: '#448AFF',
        error: '#EF5350',
        info: '#42A5F5',
        success: '#66BB6A',
        warning: '#FFA726',
        wpp: '#25D366',
        rowsel2: '#546E7A',
        rowsel: '#455A64',
        rowsuc: '#1B5E20',
        rowerr: '#531c1c',
        rowwar: '#F9A825',
        swalBkg: '#212121',
        swalText: '#fff',
        cardSuccess: '#2E7D32',
        cardWarning: '#EF6C00',
        disabled: '#212121',
        tableVta: '#263238'
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'fa',
  },
});
